/* 
Easily create dynamic heights with styles and flex.
To have dynamic heights, it requires that the height is defined for EVERY parent DOM element,
so this CSS changes the index.html level CSS so we can be certain that the heights are
defined.
*/
html,
body,
#root {
  height: 100%;
}
